<template>
    <CRow>
        <CCol sm="12">
            <CCardBody>
                <CCollapse :show="!ViewImage">
                    <CRow class="justify-content-center">
                        <CCol sm="12">
                            <addImage
                                :dataMachine="dataMachine"
                            />                         
                        </CCol>
                    </CRow>
                    <CRow class="justify-content-center">
                        <CCol sm="12">
                            <dataTableExtended
                                class="align-center-row-datatable"
                                hover
                                sorter
                                :items="formatedItems"
                                :fields="fields"
                                :items-per-page="5"
                                pagination
                                :loading="apiStateLoading"
                                column-filter
                            >
                                <template #loading>
                                    <loading/>
                                </template>
                                <template #MachineDocId="{ index}">
                                    <td>
                                        {{index+1}}
                                    </td>
                                </template>
                                <template #Icon="{item}">
                                    <td class="center-cell text-uppercase">
                                        <img
                                            :src="srcImage(item.Icon)"
                                            :alt="item.MachineDocDesc"
                                            style="height: 32px !important; width: 32px !important;"
                                            class="img-thumbnail"
                                            @error="setAltImg"
                                        />
                                    </td>
                                </template>
                                <template #status="{item}">
                                    <td class="center-cell">
                                        <CBadge v-if="item.status" color="success">
                                            {{item.Status}}
                                        </CBadge>
                                        <CBadge v-if="!item.status" color="danger">
                                            {{item.Status}}
                                        </CBadge>
                                    </td>
                                </template>
                                <template #acciones="{item,index}">
                                    <td class="center-cell">
                                        <CButton
                                            color="watch"
                                            size="sm"
                                            class="mr-1"
                                            @click="verPhoto(item, index)"
                                            v-c-tooltip="{
                                                content: $t('label.toView')+' '+$t('label.picture'),
                                                placement: 'top-end'
                                            }"
                                            
                                        >
                                            <CIcon name="eye"/>
                                        </CButton>
                                        <CButton
                                            color="edit"
                                            size="sm"
                                            class="mr-1"
                                            @click="updatedTable(item, index)"
                                            v-c-tooltip="{
                                                content: $t('label.edit')+' '+$t('label.picture'),
                                                placement: 'top-end'
                                            }"
                                        >
                                            <CIcon name="pencil"/>
                                        </CButton>

                                    </td>
                                </template>
                            </dataTableExtended>
                        </CCol>
                    </CRow>
                </CCollapse>
                <CCollapse :show="ViewImage">
                    <CCol sm="12" class="d-flex align-items-center ">
                        <CButton
                            color="edit"
                            v-c-tooltip="{
                                content: $t('label.return'),
                                placement: 'top-end'
                            }"
                            size="sm"
                            @click="ViewImage=false"
                        >
                            <CIcon name="cil-chevron-left-alt" />
                        </CButton>
                    </CCol>
                    <CCol sm="12">
                        <img :src="PhotoRoute" width="100%" height="100%"/>
                        <div class="d-flex align-items-center justify-content-end text-center"
                            style="width: 100%;  background-color: #101010;  color: white;"
                        >
                            <p class="col-sm-11 px-0 m-1">{{ MachineDocDesc }}</p>
                        </div>
                    </CCol>
                </CCollapse>
            </CCardBody>
            <CCardFooter>
                <CCol sm="12" class="d-flex justify-content-end ">
                    <CButton
                        color="wipe"
                        @click="cerrarCollapse"
                        class="m-2 mt-2"  
                    >
                    <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                    </CButton>
                </CCol>
            </CCardFooter>
        </CCol>
    </CRow>

</template>
<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { FormPhotoMachine } from '@/_validations/maquina/MaquinaValidation';
    import TipoGruaMixin from '@/_mixins/tipo-grua';
    import { GenerateImgPlaceholder } from '@/_helpers/funciones';
    import PictureInput from '@/components/picture-input';

    import GeneralMixin from '@/_mixins/general';
    import addImage from './collapse-add-image-machine';
    
    function fields(){ 
        return [
            { key: 'MachineDocId', label: '#',_style: 'width:1%; text-align:center;', filter: false},
            { key: 'MachineDocDesc',label: this.$t('label.description'), _classes:'text-center'},
            { key: 'Icon', label: this.$t('label.image'), _style: 'width: 1%;', sorter: false,  filter: false, _classes:'text-center'},
            { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width: 10%;', _classes:'text-center'},
            { key: 'FormatedDate', label: this.$t('label.date'),_style: 'width: 10%;', _classes:"text-center"},
            { key: 'status', label: this.$t('label.status'), _style: 'width: 10%;',_classes:"text-center"},
            { key: 'acciones',  label: '', _style: 'width:10%',  sorter: false,  filter: false, _classes:'text-center' }
        ]
    }


    //data
    function data() {
        return { 
            linea: false,
            ViewImage: false,
            PhotoRoute: '',
            MachineDocDesc: '',
            formPhotoMachine:{
                IdMachinePhoto:0,
                MachineId:'',
                MachinePhotoDs:'',
                MachinePhotoRoute:'',
                Image_url:'/img/picture-component-img.jpeg',
                FgActPhotoMachine:true,
            },
            formMachine:{            
                MachineId:'',
                MachineName:'',    
            },
            Image_original:null,
            srcError:'/img/picture-component-img.jpeg',
            originalAct:'',
            selectOptions: [
                { 
                    value: true, 
                    label: this.$t('label.ACTIVO')
                },
                { 
                    value: false, 
                    label: this.$t('label.INACTIVO')
                }
            ],
            dataMachine: [],
            dataImage: '',
        }
    }

    //methods
    function cerrarCollapse(){
        this.$emit('closeCollapse', true);
    }

    function srcImage(icon){
        if(icon === null || icon === '' || icon === undefined){
            return this.srcError;
        }else{
            return icon;
        }
    }
    function setAltImg(event){
        event.target.src = this.srcError;
    }
    function verPhoto(item){
        const icon = item.RouteMachineDoc;
        this.PhotoRoute = icon?`${this.$store.getters["connection/getBase"]}${icon.replace('public/', '', null, 'i')}`:GenerateImgPlaceholder();
        this.MachineDocDesc = item.MachineDocDesc;
        this.ViewImage = true;
    }
    function updatedTable(item){
        this.$store.state.maquina.machineRoute = '';
        this.$store.state.maquina.tabMachine==1;
        const id = item.MachineDocId;
        this.$store.state.maquina.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'MachineDoc-by-id',{ MachineDocId: id }, '' ).then(response => {
            try {
                if(response.status === 200){
                    const Information = response.data.data;
                    
                    if(Information.length !== 0){

                        this.dataMachine = Information;
                        const icon = Information[0].RouteMachineDoc;
                        this.dataImage = icon?`${this.$store.getters["connection/getBase"]}${icon.replace('public/', '', null, 'i')}`:GenerateImgPlaceholder();
                        this.$store.state.maquina.machineRoute = this.dataImage;
                        this.$store.state.maquina.apiStateForm = ENUM.INIT;
                    }
                }else{
                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                }
            } catch (error) {
                this.$store.commit('maquina/messageMutation', error);
                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.commit('maquina/messageMutation', err);
            this.$store.state.maquina.apiStateForm = ENUM.ERROR;
        });
    }
    
    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING /*|| this.apiState === ENUM.INIT*/){
            carga = !carga;
        }
        return carga;
    }
    function idIndentification(){
        return this.formPhotoMachine.IdMachinePhoto === 0;
    }
    function ChangeMachineId(){
        return this.formPhotoMachine.MachineId;
    }
    function computedItems () {
        return this.$store.getters["maquina/myDataPhotoMachinetable"];
    }
    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        })
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }

    export default {
        name:'add-photo-maquina',
        data,
        mixins: [TipoGruaMixin, GeneralMixin],
        directives: UpperCase,
        validations(){ return FormPhotoMachine() },
        components: {
            PictureInput,
            addImage
        },
        methods:{
            updatedTable,
            srcImage,
            verPhoto,
            setAltImg,
            cerrarCollapse,
        },
        computed: {
            fields,
            idIndentification,
            computedItems,
            formatedItems,
            ChangeMachineId,
            apiStateLoading,
            apiStateFormLoading,
            ...mapState({
                idState: state => state.maquina.idState,
                CollapseMachine: state=> state.maquina.CollapseMachine,
                apiState: state => state.maquina.apiState,
                apiStateForm: state => state.maquina.apiStateForm,
                tabMachine: state => state.maquina.tabMachine,
                formReset: state => state.maquina.formReset,
                branch: state => state.auth.branch,
                newMachineName: state => state.maquina.NewMachineName,
                newMachineId: state => state.maquina.NewMachineId,
            })
        },
        watch:{
            ChangeMachineId(newQuestion){
                if(newQuestion === ''){
                    this.$store.state.maquina.myDataPhotoMachine = [];
                }else{
                    const MachineId = newQuestion;
                    this.$store.dispatch('maquina/getDocMachinelist',{ MachineId });
                }
            },
            tabMachine: async function (newValue, OldValue) {
                if(newValue == 1){
                    if(this.idState === 0){
                        this.$store.state.maquina.myDataPhotoMachine = [];
                    }else{ 
                        this.$store.state.maquina.myDataPhotoMachine = [];
                        const MachineId = this.$store.state.maquina.idState; 
                        this.$store.dispatch('maquina/getDocMachinelist',{ MachineId });
                    }
                }else{
                    this.ViewImage = false;
                    this.PhotoRoute = '';
                    this.MachineDocDesc = '';
                }
            },
        }
    }
</script>

<style lang="scss">
.footer{
    margin: 0px;
    padding: 0px;
    border-top: 1px solid;
    border-top-color: #d8dbe0;
    top: 620px;
    left: 0px;
    position:absolute;
}
.center-cell {
  text-align: center;
}
 
</style>