var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCardBody',[_c('CCollapse',{attrs:{"show":!_vm.ViewImage}},[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{attrs:{"sm":"12"}},[_c('addImage',{attrs:{"dataMachine":_vm.dataMachine}})],1)],1),_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","items":_vm.formatedItems,"fields":_vm.fields,"items-per-page":5,"pagination":"","loading":_vm.apiStateLoading,"column-filter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"MachineDocId",fn:function({ index}){return [_c('td',[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"Icon",fn:function({item}){return [_c('td',{staticClass:"center-cell text-uppercase"},[_c('img',{staticClass:"img-thumbnail",staticStyle:{"height":"32px !important","width":"32px !important"},attrs:{"src":_vm.srcImage(item.Icon),"alt":item.MachineDocDesc},on:{"error":_vm.setAltImg}})])]}},{key:"status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[(item.status)?_c('CBadge',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.Status)+" ")]):_vm._e(),(!item.status)?_c('CBadge',{attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(item.Status)+" ")]):_vm._e()],1)]}},{key:"acciones",fn:function({item,index}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                            content: _vm.$t('label.toView')+' '+_vm.$t('label.picture'),
                                            placement: 'top-end'
                                        }),expression:"{\n                                            content: $t('label.toView')+' '+$t('label.picture'),\n                                            placement: 'top-end'\n                                        }"}],staticClass:"mr-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.verPhoto(item, index)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                            content: _vm.$t('label.edit')+' '+_vm.$t('label.picture'),
                                            placement: 'top-end'
                                        }),expression:"{\n                                            content: $t('label.edit')+' '+$t('label.picture'),\n                                            placement: 'top-end'\n                                        }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.updatedTable(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1),_c('CCollapse',{attrs:{"show":_vm.ViewImage}},[_c('CCol',{staticClass:"d-flex align-items-center",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.return'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.return'),\n                            placement: 'top-end'\n                        }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){_vm.ViewImage=false}}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('img',{attrs:{"src":_vm.PhotoRoute,"width":"100%","height":"100%"}}),_c('div',{staticClass:"d-flex align-items-center justify-content-end text-center",staticStyle:{"width":"100%","background-color":"#101010","color":"white"}},[_c('p',{staticClass:"col-sm-11 px-0 m-1"},[_vm._v(_vm._s(_vm.MachineDocDesc))])])])],1)],1),_c('CCardFooter',[_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{staticClass:"m-2 mt-2",attrs:{"color":"wipe"},on:{"click":_vm.cerrarCollapse}},[_c('CIcon',{attrs:{"name":"x"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('button.cancel')))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }